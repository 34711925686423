@import "../../utils/scss/all";

.pageSection.sliderEquipe {
    padding: 50px 9.5vw;
    display: flex;
    // background: radial-gradient(circle at bottom left, rgb(0, 83, 135) 0%, rgb(0, 0, 0) 75%);

    .section-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        width: 100%;
    }

    h2 {
        margin-bottom: 40px;
        text-align: center;
    }

    .slider-container {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }

    .swiper {
        width: 100%;
        overflow: visible;

        .swiper-slide {
            .teammate {
                opacity: .2 !important;
                transform: scaleY(.8) scaleX(1);
                transition: all .3s;

                .infos {
                    opacity: 0;
                }
            }
        }

        .swiper-slide-prev, .swiper-slide-next {
            .teammate {
                opacity: .4 !important;
                transform: scale(.9);
                margin: 0;
            }
        }

        .swiper-slide-active {

            .teammate {
                transform: scale(1);
                opacity: 1 !important;

                .infos {
                    opacity: 1;
                }
            }
        }
    }

    .arrows-container {
        margin-top: 50px;

        .arrow {
            transition: all .2s;
            cursor: pointer;
    
            &.prev {
                transform: rotate(180deg);
            }

            & + .arrow {
                margin-left: 10px;
            }
        }
    }

    @media screen and (min-width: 600px) {
        .swiper {

            .swiper-slide {
                .teammate {
                    .img-container {
                        .photo {
                            height: 50vw;
                        }
                    }
                }
            }
        }
    }

    @media screen and (min-width: 1024px) {
        padding: 75px 9.5vw;
        min-height: 100vh;
        align-items: center;
        // background: radial-gradient(circle at bottom left, rgb(0, 83, 135) 0%, rgb(0, 0, 0) 50%);

        .section-container {
            justify-content: center;
        }

        h2 {
            margin-bottom: 50px;
        }

        .swiper {

            .swiper-slide {    
                .teammate {
                    .img-container {
                        .photo {
                            height: 29vw;
                        }
                    }
                }
            }
        }

        .arrows-container {
            margin-top: 75px;

            .arrow {
                height: 31px;

                & + .arrow {
                    margin-left: 40px;
                }
            }

        }
    }
}