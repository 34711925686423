@import "../../utils/scss/all";

.pageSection.sliderCritiques {
    padding: 50px 9.5vw;
    display: flex;

    .section-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        width: 100%;
    }

    .projets-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 22px;
        width: 100%;

        h2 {
            margin-bottom: 0;
        }
    }

    .swiper {
        width: 100%;

        .swiper-slide {

            .title {
                font-weight: bold;
                margin-bottom: 8px;
            }

            .rating {
                transform: translateX(-2px);
                margin-bottom: 8px;
            }
        }
    }

    .arrows-container {

        .arrow {
            transition: all .2s;
            cursor: pointer;
    
            &.prev {
                transform: rotate(180deg);
            }

            & + .arrow {
                margin-left: 10px;
            }
        }
    }

    @media screen and (min-width: 1024px) {
        padding: 100px 7vw;
        align-items: center;

        .projets-header {
            margin-bottom: 65px;
        }

        .arrows-container {

            .arrow {
                height: 31px;

                & + .arrow {
                    margin-left: 40px;
                }
            }

        }
    }
}