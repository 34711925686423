.popupVimeo {
    position: fixed;
    top: 0; right: 0; left: 0; bottom: 0;
    width: 100%;
    height: 100vh;
    z-index: 2999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, .5);

    .player {
        width: 80vw;
        max-height: 90vh;
        animation: popUp ease-out .3s;
    }

    #closeVideo {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
        color: white;
    }

    @keyframes popUp {
        from { transform: scale(0) }
        to { transform: scale(1) }
    }
}