@import "../../utils/scss/all";

.ficheBureau {
    width: 100%;

    .main-img {
        width: 100%;
        height: 45vw;
        object-fit: cover;
    }

    .title {
        font-style: normal;
        font-size: 22px;
        font-weight: 900;
        line-height: 25px;
        margin-top: 10px;
        margin-bottom: 5px;
        display: block;
    }

    .btn {
        margin-top: 15px;
    }

    @media screen and (min-width: 1024px) {
        .title {
            font-size: 45px;
            letter-spacing: 1.35px;
            line-height: 45px;
            margin-top: 15px;
            margin-bottom: 20px;
        }

        .btn {
            margin-top: 20px;
        }
    }
}