@import "../../../utils/scss/all";

.metiers-drawers {

    & + .metiers-drawers {
        margin-top: 0;
    }

    h3 {
        color: $blue;
        opacity: .55;
        font-size: min(10vw, 150px);
        letter-spacing: 2.37px;
        text-transform: uppercase;
        font-weight: 900;
        word-break: break-all;
        line-height: min(10vw, 150px);
        text-align: center;
    }

    .sous-metiers {
        display: flex;
        flex-direction: column;
        position: relative;
        top: 10px;
        justify-content: center;
        align-items: center;

        h4 {
            font-size: min(6.6vw, 50px);
            letter-spacing: .75px;
            font-weight: normal;
            transition: all .2s;
            padding-bottom: 20px;
            display: inline-block;
            text-align: center;
            width: 100%;
        }

        .sous-metier-drawer:hover h4 {
            text-shadow: 0px 0px 16px $white;
            font-weight: bold;
        }

        .entites {
            display: flex;
            flex-direction: column;
            gap: 20px;
            align-items: center;
            margin-bottom: 20px;
            overflow: hidden;

            .entite-img-container {

                img {
                    height: min(7vw, 36px);
                    filter: grayscale(1) brightness(1000%);
                    transition: all .2s;

                    &:hover {
                        filter: none;
                    }
                }
            }
        }
    }

    @media screen and (min-width: 769px) {
        h3 {
            font-size: min(11vw, 150px);
            line-height: min(16vw, 150px);
        }

        .sous-metiers {
            top: -5vw;
        }
    }

    @media screen and (min-width: 1024px) {
        margin-top: 0 !important;

        .sous-metiers {
            flex-direction: row;
            column-gap: 70px;
            flex-wrap: wrap;
            top: -60px;
            align-items: flex-start;

            .entites {
                align-items: flex-start;
            }
        }
    }
}