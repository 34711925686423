@import "../../utils/scss/all";

.pageSection.presentation {
    padding: 55px 9.5vw 85px;
    background-color: white;
    // color: black;
    .section-container {
        max-width: 1355px;
        margin: auto;

        img {
            width: 100%;
        }

        .text {
            margin-top: 35px;
        }
    }

    .btn {
        margin-top: 35px;
        background-color: white;
        color: black;
    }

    @media screen and (min-width: 769px) {
        padding: 55px 7vw 85px;
    }

    @media screen and (min-width: 1024px) {
        .section-container {
            display: flex;
            align-items: center;
            gap: 11%;
            min-height: 100vh;

            img {
                width: 39%;
            }

            .text {
                margin-top: 0;
                width: 61%;
            }
        }
    }
}