@import "../../utils/scss/all";

.pageSection.projetGallery {
    padding: 40px 9.5vw;

    .gallery {
        display: flex;
        flex-wrap: wrap;
        column-gap: 10px;
        row-gap: 20px;

        .projet-item {
            width: calc(50% - 10px/2);

            .main-img {
                width: 100%;
                height: 56vw;
                object-fit: cover;
                -webkit-mask-image: var(--letter-mask), linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 1));
                mask-image: var(--letter-mask), linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 1));
                mask-repeat: no-repeat;
                mask-position: center;
                mask-size: auto 60%, 100%;
                transition: all .6s;

                &:hover {
                    -webkit-mask-image: var(--letter-mask), linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
                    mask-image: var(--letter-mask), linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
                }
            }

            .title {
                font-style: normal;
                font-size: 14px;
                letter-spacing: .42px;
                line-height: 22px;
                font-weight: 900;
                margin: 6px 0 4px;
                display: block;
            }

            .desc {
                font-size: 12px;
                line-height: 14px;
            }
        }
    }

    .arrow {
        height: 20px;
        transform: rotate(90deg);
        cursor: pointer;
        transition: all .2s;
        margin: 50px auto 0;
        display: block;
    }

    @media screen and (min-width: 769px) {
        padding: 50px 9.5vw 75px;

        .gallery {
            .projet-item {
                width: calc(33.33% - 10px*2/3);

                .main-img {
                    height: 36vw;
                }
            }
        }

        .arrow {
            height: 37px;
            margin-top: 75px;

            &:hover {
                transform: rotate(90deg) translateX(10px);
            }
        }
    }

    @media screen and (min-width: 1024px) {
        padding: 75px 7vw 100px;

        .gallery {
            column-gap: 30px;
            row-gap: 60px;

            .projet-item {
                width: calc(25% - 30px*3/4);

                .main-img {
                    height: 28vw;
                }

                .title {
                    font-size: 22px;
                    letter-spacing: .66px;
                    line-height: 30px;
                    margin: 10px 0 12px;
                }
    
                .desc {
                    font-size: 16px;
                    letter-spacing: .48px;
                    line-height: normal;
                }
            }
        }
    }
}