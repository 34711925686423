@import "../../utils/scss/all";

.partenaires {
    padding: 50px 9.5vw;
    // background: radial-gradient(circle at bottom left, rgb(0, 83, 135) 0%, rgb(0, 0, 0) 85%);

    .desc {
        margin-bottom: 40px;
    }

    .grid {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        // column-gap: 8%;
        row-gap: 35px;
        // margin-bottom: 50px;

        .grid-item {
            // width: 28%;
            width: 33%;

            img {
                max-width: 100%;
                // max-height: 35px;
                max-height: 45px;
                margin: auto;
                display: block;
            }

            .more {
                font-weight: bold;
                text-align: center;
            }

            &:last-child {
                width: 100%;
            }
        }
    }

    .contact-us {
        margin: 0;
    }

    @media screen and (min-width: 769px) {
        padding: 50px 7vw;
        // background: radial-gradient(circle at bottom left, rgb(0, 83, 135) 0%, rgb(0, 0, 0) 55%);

        .grid {
            column-gap: 8%;

            .grid-item {
                width: 19%;

                img {
                    // max-height: 40px;
                    max-height: 75px;
                }

                .more {
                    text-align: left;
                    white-space: nowrap;
                }

                &:last-child {
                    width: 19%;
                }
            }
        }
    }

    @media screen and (min-width: 1200px) {
        padding: 120px 7vw;

        .text {
            width: 45%;

            .desc {
                margin-bottom: 80px;
            }
        }

        .grid {
            // margin-bottom: 120px;
            column-gap: 5%;

            .grid-item {
                width: 12%;

                .more {
                    text-align: left;
                    white-space: nowrap;
                }

                &:last-child {
                    width: 19%;
                }
            }
        }
    }
}