@import "../../utils/scss/all";

.pageSection.superProjets {
    padding: 30px 9.5vw;
    background: white;
    color: black;

    .section-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    
        .projets-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 30px;
            width: 100%;
    
            h2 {
                margin-bottom: 0;
            }
    
            .arrow {
                transition: all .2s;
                cursor: pointer;
        
                &.prev {
                    transform: rotate(180deg);
                }
        
                &.swiper-button-disabled {
                    opacity: .5;
                }
    
                & + .arrow {
                    margin-left: 10px;
                }
            }
        }
    
        .swiper {
            height: 135vw;
            width: 100%;
    
            .swiper-slide {
                height: calc((100% - 30px) / 2) !important;
    
                a {
                    display: block;
                    text-decoration: none;
                    color: $white;
                }
    
                img {
                    width: 100%;
                    height: 55vw;
                    object-fit: cover;
                    object-position: center;
                    -webkit-mask-image: var(--letter-mask), linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
                    mask-image: var(--letter-mask), linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
                    mask-repeat: no-repeat;
                    mask-position: center;
                    mask-size: auto 60%, 100%;
    
                    &:hover {
                        -webkit-mask-image: var(--letter-mask), linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 1));
                        mask-image: var(--letter-mask), linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 1));
                    }
                }
    
                p {
                    font-weight: bold;
                    margin-top: 5px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    color: black;
                }
            }
        }
    
        & > .btn {
            margin-top: 20px;
            background-color: black;
            color: white;
        }
    }

    @media screen and (min-width: 600px) {
        .section-container {
            .swiper {
                height: 60vw;
    
                .swiper-slide {
                    height: 100% !important;
                }
            }
        }
    }

    @media screen and (min-width: 769px) {
        padding: 75px 7vw;

        .section-container {
            .swiper {
                height: calc(28.75vw + 15px + 30px);
    
                .swiper-slide {
                    img {
                        height: 28.75vw;
                    }
    
                    p {
                        margin-top: 15px;
                    }
                }
            }
    
            & > .btn {
                margin-top: 70px;
            }
        }
    }

    @media screen and (min-width: 1024px) {
        .section-container {

            .projets-header {
                .arrow {
                    height: 31px;
    
                    & + .arrow {
                        margin-left: 40px;
                    }
                }
            }
        }
    }
}