@import "../../utils/scss/all";

.pageSection.chiffres {
    text-align: center;
    padding: 60px 9.5vw;
    min-height: 100vh;

    h2 {
        margin-bottom: 35px;
    }

    .split-flap-container {
        margin-bottom: 15px;
    }

    @media screen and (min-width: 1024px) {
        padding: 60px 7vw;

        h2 {
            margin-bottom: 50px;
        }

        .split-flap-container {
            margin-bottom: 25px;
        }
    }

    @media screen and (min-width: 1200px) {
        display: flex;
        gap: 10%;
        padding: 17vh 7vw;

        h2 {
            text-align: left;
            max-width: 388px;
        }

        .split-flap-container {
            margin-bottom: 25px;
            display: flex;
            align-items: center;
            gap: 15px;
        }
    }

    @media screen and (min-width: 1600px) {
        gap: 15%;
    }
}
