@import "../../utils/scss/all";

.pageSection.textImgBandeau {
    padding: 50px 9.5vw;

    .textImgBandeauContainer {
        margin-top: 35px;
    }

    @media screen and (min-width: 1024px) {
        padding: 100px 7vw;

        .textImgBandeauContainer {
            margin-top: 100px;
        }
    }
}