@import "../../utils/scss/all";

header#nav-header {
    width: 100%;
    z-index: $header-z-index;
    position: fixed;
    top: 0;
    overflow: hidden;

    #logo {
        height: 27px;
        display: block;
        transition: all .2s;
    }

    #headerMain {
        padding: 0 9.5vw;
        z-index: calc($header-z-index + 1);
        transition: all .2s;
        background: #E5007E;

        &.open {
            background: transparent !important;
        }

        .header-container {
            padding: 15px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid var(--borderColor);
        }

        .burger-nav {
            display: flex;
            align-items: center;
            gap: 6vw;

            .langContainer {
                a {
                    color: $white;
                    text-decoration: none;
                    text-transform: uppercase;
                    padding: 0 10px;
                    transition: all .2s;

                    & + a {
                        border-left: 1px solid $white;
                    }

                    &.active {
                        font-weight: bold;
                    }

                    &:hover {
                        text-shadow: 0px 0px 8px $white;
                    }
                }
            }
        }

        .menuBurger {
            $h: 17px;
            $w: 25px;
            width: $w;
            height: $h;
            cursor: pointer;
            transition: .5s ease-in-out;
            transform: rotate(0deg);
            position: relative;

            span {
                background-color: $white;
                width: 100%;
                height: calc($h/5);
                display: block;
                position: absolute;
                opacity: 1;
                transform: rotate(0deg);
                transition: .25s ease-in-out;
                left: 0;

                &:nth-child(1) { top: 0 }
                &:nth-child(2) { top: calc($h/5 * 2) }
                &:nth-child(3) { top: calc($h/5 * 4) }
            }

            &.open {
                span {
                    width: 85%;

                    &:nth-child(1) {
                        top: calc($h/5 * 2);
                        transform: rotate(135deg);
                    }
                    &:nth-child(2) {
                        opacity: 0;
                        left: -35px;
                    }
                    &:nth-child(3) {
                        top: calc($h/5 * 2);
                        transform: rotate(-135deg);
                    }
                }
            }
        }
    }

    #mobileNav {
        width: 100%;
        padding: 55px 9.5vw 20px;

        nav {
            color: $white;
            // font-size: 13px;
            font-size: 15px;
            letter-spacing: 0.39px;
            display: flex;
            flex-direction: column;
            gap: 25px;

            a {
                text-decoration: none;
                color: $white;
            }
    
            .link-list {
                & > a {
                    font-weight: bold;
                    margin-bottom: 10px;
                    display: inline-block;
                }
    
                .chevron {
                    margin-right: -10px;
                    transition: all .2s;
                    position: relative;
                    left: -20px;
                    transform: scaleY(1);
    
                    &.expanded {
                        transform: scaleY(-1);
                    }
                }
    
                .sub-menu {
                    display: flex;
                    flex-direction: column;
                    margin-left: 10px;
                    gap: 10px;
                    overflow: hidden;
                }

                a:hover {
                    transform: text-shadow .2s;
                    text-shadow: 0px 0px 8px $white;
                }
            }
        }

        .mobile-nav-container > .btn {
            margin-top: 25px;
            margin-bottom: 10px;
            font-size: 15px;
        }
    }

    &.transparent {
        #headerMain {
            background: transparent;
        }
    }

    &[data-safari="true"][data-open="true"] {
        background-color: rgba(0, 0, 0, .5);
    }

    &.bg-white:not(.transparent-white) {
        color: $black;
        background: rgba(255, 255, 255, .7);

        
        &[data-safari="true"][data-open="true"] {
            background-color: rgba(255, 255, 255, .95);
        }

        // #logo {

        //     filter: invert(100%) sepia(51%) saturate(2%) hue-rotate(69deg) brightness(1000%) contrast(100%);

        // }

        a:not(.btn) {
            color: $black !important;
        }

        #headerMain {
            background-color: $white;

            .burger-nav {
                .langContainer {
                    a {
                        & + a {
                            border-left-color: $black;
                        }
                        &:hover {
                            text-shadow: 0px 0px 8px rgba(0, 0, 0, .5);
                        }
                    }
                }
            }

            .menuBurger {
                span {
                    background-color: $black;
                }
            }
        }

        #mobileNav {
            nav {
                color: $black;

                a {
                    color: $black;
                }

                .link-list {

                    .chevron {
                        filter: brightness(-100%);
                    }

                    a:hover {
                        text-shadow: 0px 0px 8px rgba(0, 0, 0, .5);
                    }
                }
            }
        }
    }

    &.transparent-white {
        .material-symbols-outlined{
            color: white;
        }
        background: transparent;
        
        #headerMain {
            background: transparent;

            .header-container {
                border-bottom-color: rgba(255, 255, 255, .5);
            }
        }
    }

    @media screen and (min-width: 768px) {
        
        #mobileNav {
            nav {
                font-size: 18px;
            }


            .mobile-nav-container > .btn {
                font-size: 18px;
            }
        }
    }

    @media screen and (min-width: 1024px) {
        
        #headerMain {
            padding: 0 7vw;

            .burger-nav {
                gap: 4vw;
            }
        }

        #mobileNav {
            padding: 55px 7vw 20px;
        }
    }

    @media screen and (min-width: 1025px) {

        #logo {
            height: 42px;
        }

        #headerMain {
            .burger-nav {
                .langContainer .langElem {
                    font-size: 18px;
                }
            }
        }
        
        #mobileNav {
            align-items: center;
            padding: 0 7vw;
            height: 74px;

            .mobile-nav-container {
                display: flex;
                justify-content: space-between;
                height: 100%;
                align-items: center;

                & > .btn {
                    margin: 0;
                }
            }

            nav {
                flex-direction: row;
                align-items: center;
                gap: 4vw;
                height: 100%;

                .link-list {
                    height: 100%;

                    & > a {
                        margin-bottom: 0;
                        height: 100%;
                        vertical-align: middle;
                        line-height: 74px;
                    }

                    .chevron {
                        width: 15px;
                        margin-right: 5px;
                        left: 0;
                    }

                    .sub-menu {
                        position: absolute;
                        left: 0;
                        top: 147px;
                        margin: 0;
                        flex-direction: row;
                        padding: 25px 7vw;
                        width: 100%;
                        justify-content: space-evenly;
                    }
                }
            }

            &.expanded .mobile-nav-container {
                border-bottom: 1px solid var(--borderColor);
            }
        }
    }
}

.material-symbols-outlined{
    cursor: pointer;
}