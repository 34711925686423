.contact-us {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #e5007e;
  min-height: auto;
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;

  div {
    border-top: 1px solid var(--borderColor);
    padding: 25px 0;
    display: flex;
    row-gap: 3rem;
    justify-content: space-between;
    padding-left: 2.5%;
    padding-right: 2.5%;
    align-items: center;
    flex-direction: column;
    width: 86%;
    margin: 0 0vw;
    p {
      font-size: 14px;
      letter-spacing: 0.42px;
      font-weight: 900;
      text-align: center;
    }

    .btn {
      white-space: nowrap;
    }

    @media screen and (min-width: 769px) {
      margin: 0 0vw;
    }

    @media screen and (min-width: 1024px) {
      flex-direction: row;

      p {
        font-size: 21px;
        letter-spacing: 0.66px;
        font-weight: bold;
      }
    }
  }
}
