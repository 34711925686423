@import "../../utils/scss/all";

.pageSection.bureaux {
    padding: 33px 9.5vw 55px;

    h2 {
        margin-bottom: 33px;
    }

    .gallery {
        display: flex;
        gap: 33px;
        flex-wrap: wrap;
    }

    @media screen and (min-width: 769px) {
        .gallery {
            column-gap: 30px;
            row-gap: 70px;

            .ficheBureau {
                width: calc(50% - 30px/2);

                .main-img {
                    height: 22.5vw;
                }
            }
        }
    }

    @media screen and (min-width: 1024px) {
        padding: 70px 7vw 150px;

        h2 {
            margin-bottom: 70px;
        }
    }
}