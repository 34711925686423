@import "../../utils/scss/all";

.pageSection.actuSpec {

    background: radial-gradient(circle at bottom, rgb(0, 83, 135) 0%, rgb(0, 0, 0) 75%);

    .main-img {
        width: 100%;
        height: 50vh;
        object-fit: cover;
    }

    .main-content {
        padding: 25px 9.5vw 50px;

        .btn {
            margin-top: 25px;
        }
    }

    @media screen and (min-width: 1024px) {

        background: black;
        
        .section-container {
            display: flex;
        }

        .main-img {
            width: 35vw;
            height: unset;
        }
        
        .main-content {
            padding: 7vw 7vw 7vw 6vw;
            width: 65vw;
            align-self: center;
        }
    }
}