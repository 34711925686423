@import "../../utils/scss/all";

.pageSection.heroHome {
    height: 100vh;

    .section-container {
        height: 100%;
        display: flex;
        flex-direction: column-reverse;
        width: 100%;

        .hero-slider {
            height: calc(100% - 116px);
            width: 100%;
    
            .swiper-slide {
                height: 100%;
    
                &.text-slide {
                    padding: 70px 8vw 25px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
    
                    h1 {
                        font-weight: 900;
                        text-transform: lowercase;
            
                        > span {
                            white-space: nowrap;
                            display: block;
                        }
            
                        #first-line {
                            font-size: min(15vw, 235px);
                            text-align: right;
                            line-height: 1;
                        }
            
                        #second-line {
                            font-size: min(24vw, 375px);
                            text-align: center;
                            margin-top: max(-7vw, -114px);
                        }
                    }
            
                    .infos {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        
                        .subtitle {
                            text-align: center;
                            margin-top: 30px;
                        }
                
                        .btn {
                            margin-top: 35px;
                        }
                    }
                }
        
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }
    
        .hero-footer {
            border-bottom: 1px solid var(--borderColor);
            margin: 0 10vw;
            text-align: center;
            padding: 0 0 25px;
            display: flex;
            flex-direction: column;
            align-items: center;
    
            .hero-title-slider {
                height: 70px;
                text-transform: uppercase;
                text-align: center;
                width: 100%;
    
                .swiper-slide {
                    display: flex;
                    align-items: center;
                    width: 100%;
                }
    
                a {
                    width: 100%;
                    font-size: 16px;
                    font-weight: 900;
                    letter-spacing: 0.48px;
                    color: white;
                    text-decoration: none;
                }
            }
        
            .arrow {
                margin: 0 10px;
                transition: all .2s;
                cursor: pointer;
        
                &.prev {
                    transform: rotate(180deg);
                }
        
                &.swiper-button-disabled {
                    opacity: .5;
                }
            }
        }
    }


    @media screen and (min-width: 769px) {
        padding-top: 75px !important;

        .section-container {

            .hero-slider {
                height: calc(100% - 134px);
    
                .swiper-slide.text-slide {
                    padding-bottom: 0;
                    padding-top: 0;
                }
            }
            
            .hero-footer {
                flex-direction: row;
                padding: 50px 0;
                margin: 0 7vw;
    
                .hero-title-slider {
                    text-align: left;
                    height: auto;
    
                    a {
                        font-size: 22px;
                        letter-spacing: 0.66px;
                    }
                }
    
                .arrows-container {
                    display: flex;
    
                    .arrow {
                        margin: 0 14px;
                        height: 33px;
                        display: block;
                    }
                }
            }
        }
    }

    @media screen and (min-width: 1200px) {
        .section-container {

            .hero-slider {
                .swiper-slide.text-slide {
                    .infos {
                        align-self: flex-end;
                        width: min(33vw, 578px);
                        margin-top: -$header-height;
                        align-items: flex-start;
    
                        @media screen and (min-width: 1800px) {
                            margin-right: 4vw;
                        }
    
                        @media screen and (min-width: 1025px) {
                            margin-top: -72px;
                        }
        
                        .subtitle {
                            text-align: left;
                            margin: 0;
                        }
    
                        .btn {
                            margin-top: 33px;
                        }
                    }
                }
    
                .swiper-slide img {
                    -webkit-mask-image: var(--letter-mask), linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
                    mask-image: var(--letter-mask), linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
                    mask-repeat: no-repeat;
                    mask-position: center;
                    mask-size: auto 80%, 100%;
                    transition: all .6s;
    
                    &:hover {
                        -webkit-mask-image: var(--letter-mask), linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 1));
                        mask-image: var(--letter-mask), linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 1));
                    }
                }
            }
        }
    }
}