@import "../../utils/scss/all";

.pageSection.articleList {
    padding: 50px 9.5vw;

    .gallery {
        display: flex;
        gap: 40px;
        flex-direction: column;

        .article-item {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            border-bottom: 1px solid var(--borderColor);
            padding-bottom: 7px;

            .title {
                font-size: 14px;
                letter-spacing: 0.42px;
                line-height: 22px;
                font-weight: 900;
                font-style: normal;
            }

            .theme {
                font-size: 12px;
                line-height: 14px;
            }
        }
    }

    @media screen and (min-width: 769px) {
        .gallery {
            gap: 50px;
        }
    }

    @media screen and (min-width: 1024px) {
        padding: 50px 7vw;

        .gallery {
            gap: 25px;

            .article-item {
                flex-direction: row-reverse;
                align-items: center;
                padding: 25px 0 50px;

                .title {
                    font-size: 22px;
                    letter-spacing: 0.66px;
                    line-height: 30px;
                    width: 82.5%;
                    border-left: 4px solid var(--bgColor);
                    padding-left: 55px;
                }

                .theme {
                    width: 17.5%;
                    font-size: 16px;
                    letter-spacing: .48px;
                    line-height: 30px;
                }
            }
        }
    }
}