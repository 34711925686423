@import "../../utils/scss/all";

.pageSection.recrutFestiv {
    padding: 30px 9.5vw;
    // background: radial-gradient(circle at bottom, rgb(0, 83, 135) 0%, rgb(0, 0, 0) 100%);

    @media screen and (min-width: 1024px) {
        padding: 50px 7vw;
        // background: radial-gradient(circle at bottom left, rgb(0, 83, 135) 0%, rgb(0, 0, 0) 45%);
    }
}
