@import "../../../utils/scss/all";

.autocomplete-custom {
    .static-popper {
        position: static !important;
        transform: none !important;

        .MuiPaper-root * {
            color: $white;
            padding-left: 0;
            padding-right: 0;
        }

        .MuiAutocomplete-listbox {
            padding-bottom: 0;
        }
    }
}

.bg-white {
    .autocomplete-custom {
        .static-popper {
    
            .MuiPaper-root * {
                color: $black;
            }
        }
    }
}