@import "../../utils/scss/all";

.pageSection.actuDetails {
    padding-top: 0 !important;
    
    .banner {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        min-height: 43vh;
        padding: 75px 9.5vw 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: 10px;
        position: relative;
        color: white;

        .entites {
            width: 100%;
            position: relative;
            display: flex;
            align-items: flex-end;
            gap: 6px;
            flex-wrap: wrap;

            .prim-tag {
                background-color: $white;
                width: 50%;
                color: $black;
                padding: 8px 12px;
                border-radius: 43px;
                font-weight: bold;
                text-align: center;
                max-width: 185px;
                min-width: 145px;

                p {
                    line-height: normal;
                    margin: 4px 0;
                }

                img {
                    height: 24px;
                    display: block;
                    margin: auto;
                    max-width: 100%;
                    object-fit: contain;
                }
            }
        }

        h1 {
            position: relative;
            font-size: 25px;
            font-weight: 900;
            line-height: 25px;
        }

        .infos {
            position: relative;
        }

        &::before {
            content: linear-gradient(#000000CC, #00000000);
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: .6;
            mix-blend-mode: multiply;
        }
    }

    .main-content {
        padding: 25px 9.5vw;
        
        a {
            text-decoration: underline;
        }
    }

    @media screen and (min-width: 769px) {
        .banner {
            .sous-metiers, .entites {
                .prim-tag {
                    img {
                        height: 30px;
                    }
                }
            }
        }

        .main-content {
            .data-list {
                .list-item {
                    .tag {
                        font-size: 16px;
                        letter-spacing: 0.48px;
                        line-height: 16px;
                    }
                }
            }
        }
    }

    @media screen and (min-width: 1024px) {
        .banner {
            min-height: 75vh;
            padding: 100px 7vw 55px;
            gap: 20px;

            .entites {
                gap: 15px;
            }

            h1 {
                font-size: 50px;
                line-height: 55px;
                letter-spacing: 1.5px;
            }

            .infos {
                font-size: 16px;
            }
        }

        .main-content {
            padding: 60px 7vw;
        }
    }
}