@import "../../utils/scss/all";

.pageSection.notFound {
    padding: 60px 9.5vw !important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background: radial-gradient( farthest-side at bottom left, #F090BB  0%, #E5007E 100%);
    h1 {
        font-size: 16px;
        letter-spacing: 0.48px;
        margin-bottom: 11px;
    }

    .split-flap-container {
        margin-bottom: 11px;
    }

    .btn {
        margin-top: 25px;
    }

    @media screen and (min-width: 769px) {
        padding: 115px 7vw;

        h1 {
            font-size: 22px;
            letter-spacing: .66px;
            margin-bottom: 28px;
        }

        .split-flap-container {
            margin-bottom: 25px;
        }

        .btn {
            margin-top: 45px;
        }
    }
}