@import "../../utils/scss/all";

.pageSection.simpleText {
  padding: 70px 9.5vw;
  margin-top: 4rem;
  h1 {
    margin-bottom: 40px;
  }

  ul {
    margin-left: 1.5em;
    margin-top: 1em;
  }

  a {
    text-decoration: underline;
  }

  @media screen and (min-width: 1024px) {
    padding: 115px 7vw;

    h1 {
      margin-bottom: 55px;
    }
  }
}
