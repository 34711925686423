@import "../../../utils/scss/all";

.custom-select {
    background-color: $white;
    color: $black;
    padding: 10px 15px;
    transition: all .2s;
    border: 1px solid $white;
    border-radius: 3px;
    display: inline-block;
    text-decoration: none;
    text-align: center;
    font-weight: bold;
    font-size: 14px;

    &:hover {
        background-color: transparent;
        color: $white;
    }

    @media screen and (min-width: 769px) {
        font-size: 18px;
    }
}

.bg-white .custom-select {
    background-color: $black;
    color: $white;
    border-color: $black;

    &:hover {
        color: $black;
        background-color: transparent;
    }
}