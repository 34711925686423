@import "../../utils/scss/all";

.pageSection.autresEntites {
    padding: 40px 9.5vw;

    h2 {
        text-align: center;
    }

    .list {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .list-item {
            border: .5px solid rgba(0, 0, 0, 0.5);
            padding: 28px;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;

            .main-logo {
                width: 50%;
            }

            .text {
                display: flex;
                flex-direction: column;
                align-items: center;

                .desc {
                    text-align: center;
                    margin: 25px 0;
                }

                .full-logo {
                    width: 45%;
                    margin-top: 17px;
                }
            }

            .item-footer {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
    }

    @media screen and (min-width: 769px) {

        h2 {
            margin-bottom: 50px;
        }

        .list {
            flex-direction: row;
            gap: 26px;

            .list-item {
                width: calc(50% - 13px);
            }
        }
    }

    @media screen and (min-width: 1024px) {
        padding: 80px 7vw 110px;
    }

    @media screen and (min-width: 1200px) {
        .list {
            flex-wrap: wrap;

            .list-item {
                flex-direction: row;
                padding: 45px;
                gap: 5%;
                width: 100%;

                .main-logo {
                    width: 29%;
                }

                .text {
                    display: block;

                    .desc {
                        margin: 0 0 35px;
                        text-align: left;
                    }

                    .item-footer {
                        flex-direction: row;
                        gap: 25px;
                        align-items: center;
                    }

                    .full-logo {
                        margin-top: 0;
                        width: auto;
                        height: 42px;
                    }
                }
            }
        }
    }

    @media screen and (min-width: 1400px) {
        .list {
            .list-item {
                width: calc(50% - 13px);
            }
        }
    }
}