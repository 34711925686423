@import "../../utils/scss/all";

.pageSection.projetDetails {
    padding-top: 0 !important;

    .banner {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        min-height: 61vw;
        padding: 75px 9.5vw 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        gap: 6px;
        position: relative;

        .sous-metiers, .entites {
            width: 50%;
            position: relative;
            display: flex;
            align-items: flex-end;
            flex-direction: column;
            gap: 6px;

            .prim-tag {
                background-color: $white;
                width: 100%;
                color: $black;
                padding: 8px 12px;
                border-radius: 43px;
                font-weight: bold;
                text-align: center;
                max-width: 185px;

                p {
                    line-height: normal;
                    margin: 4px 0;
                }

                img {
                    height: 24px;
                    display: block;
                    margin: auto;
                    max-width: 100%;
                    object-fit: contain;
                }
            }
        }

        &::before {
            content: linear-gradient(#000000CC, #00000000);
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: .6;
            mix-blend-mode: multiply;
        }
    }

    .main-content {
        padding: 0 9.5vw 50px;

        .affiche-container {
            display: flex;
            align-items: flex-end;
            gap: 10px;
            justify-content: space-between;
            margin-top: calc(0px - .75 * 55vw);
            position: relative;

            .affiche {
                width: calc(50% - 10px);
                height: 55vw;
                object-fit: cover;
                display: block;
            }

            .btn-container {
                width: 50%;
                display: flex;
                justify-content: center;
                max-width: 185px;

                .btn {
                    white-space: nowrap;
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: block;
                }
            }
        }

        .infos {
            margin-bottom: 33px;
            width: 100%;

            .head {
                padding: 12px 0 17px;
                border-bottom: 1px solid var(--borderColor);
                margin-bottom: 17px;

                h1 {
                    font-size: 25px;
                    font-weight: 900;
                }
            }
        }

        .data-list {
            .list-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                column-gap: 10px;
                row-gap: 5px;

                .tag {
                    background-color: var(--bgColor);
                    border-radius: 50px;
                    color: var(--opposite);
                    padding: 5px 15px;
                    white-space: nowrap;
                    font-size: 12px;
                    letter-spacing: 0.36px;
                    line-height: 14px;
                }
            
                & + .list-item {
                    margin-top: 5px;
                }
            }
        }
    }

    @media screen and (min-width: 769px) {
        .banner {
            .sous-metiers, .entites {
                .prim-tag {
                    img {
                        height: 30px;
                    }
                }
            }
        }

        .main-content {
            .data-list {
                .list-item {
                    .tag {
                        font-size: 16px;
                        letter-spacing: 0.48px;
                        line-height: 16px;
                    }
                }
            }
        }
    }

    @media screen and (min-width: 1024px) {
        .banner {
            height: 55vh;
            min-height: unset;;
            padding: 100px 7vw 40px;
            gap: 15px;

            .sous-metiers, .entites {
                padding-left: calc(20vw + 65px);
                width: 100%;
                justify-content: flex-start;
                flex-direction: row;
                align-items: center;
                gap: 15px;
            }
        }

        .main-content {
            padding: 0 7vw 140px;
            display: flex;
            align-items: flex-start;

            .affiche-container {
                margin-top: calc(0px - .5 * 30vw);
                flex-direction: column;
                align-items: stretch;
                gap: 30px;

                .affiche {
                    width: 20vw;
                    height: 30vw;
                }
            }

            .infos {

                .head {
                    padding: 15px 0 23px 65px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 66px;

                    h1 {
                        font-size: 50px;
                        letter-spacing: 1.5px;
                        line-height: normal;
                    }
                }

                .synopsis {
                    padding-left: 65px;
                }
            }

            .data-list {
                .list-item + .list-item {
                    margin-top: 15px;
                }
            }
        }
    }
}