@import "../../utils/scss/all";

.pageSection.heroGroup {
    padding: 50px 9.5vw;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .section-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .logo-group {
        // width: 195px;
        margin: 0 auto 30px;
        display: block;
        height: 44px;
    }

    .desc {
        padding: 25px 0;
        text-align: center;
        border-top: .5px solid rgba(255, 255, 255, 0.5);
        max-width: 600px;
    }

    .arrow {
        height: 20px;
        transform: rotate(90deg);
        cursor: pointer;
        transition: all .2s;
    }

    @media screen and (min-width: 769px) {
        .logo-group {
            margin-bottom: 50px;
            // width: 405px;
            height: 67px;
        }

        .desc {
            padding: 40px 0;
        }

        .arrow {
            height: 37px;
            margin-top: 50px;

            &:hover {
                transform: rotate(90deg) translateX(10px);
            }
        }
    }

    @media screen and (min-width: 1024px) {
        padding: 100px 7vw;
    }
}