@import "../../utils/scss/all";

.pageSection.equipe {
    padding: 50px 9.5vw;

    h2 {
        text-align: center;
        margin-bottom: 45px;
    }

    .entity-hover{
        .desc{
            font-size: x-large;
        }
    }
   

    .equipe-list {
        display: flex;
        flex-wrap: wrap;
        column-gap: 10px;
        row-gap: 12px;
        // justify-content: space-between;
    }

    @media screen and (min-width: 1024px) {
        padding: 100px 7vw;

        h2 {
            text-align: left;
            margin-bottom: 60px;
        }

        .equipe-list {
            column-gap: 30px;
            row-gap: 65px;
        }
    }
}
