@import "../../utils/scss/all";

.recrut {

    .main-img {
        width: 100%;
        margin-bottom: 20px;
        object-fit: cover;
    }

    .text {
        h2 strong {
            display: block;
        }

        .desc {
            margin-bottom: 30px;
        }
    }

    .btn {
        white-space: nowrap;
        margin-bottom: 30px;
    }

    @media screen and (min-width: 1024px) {

        .section-container {
            display: flex;
            gap: 5%;
            border-top: 1px solid var(--borderColor);
            border-bottom: 1px solid var(--borderColor);
        }

        .main-img {
            width: 40%;
            margin-bottom: 0;
        }

        .infos {
            padding: 40px 0;
            width: 60%;
        }

        .text {
            align-self: center;

            h2 strong {
                display: inline;
            }
        }

        .btn {
            align-self: center;
            white-space: nowrap;
            margin-right: 5%;
            margin-bottom: 0px;
        }
    }

    @media screen and (min-width: 1200px) {

        .main-img {
            width: 29%;
        }

        .infos {
            display: flex;
            gap: 5%;
            width: 71%;
        }

        .text {
            .desc {
                margin-bottom: 0;
            }
        }
    }
}

.pageSection.recrut {
    margin: 0 9.5vw;

    @media screen and (min-width: 1024px) {
        margin: 0 7vw;
    }
}