.section-login {
  background-color: #e5007e;
  height: 70vh;
  min-height: 60rem;
  padding-bottom: 3rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5rem;

  .error-message{
    font-weight: 700;
    text-align: center;
  }

  .error-input{
    border-color: rgb(255, 0, 0);
  }

  &-content {
    width: 86%;
    height: 50vh;
    display: flex;

    &-left {
      width: 25%;
      height: 100%;
    }

    &-right {
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      &-form {
        width: 40rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding-top: 2rem;

        h3 {
          margin-bottom: 1rem;
          font-size: x-large;
        }

        &-desc {
          margin-bottom: 2rem;
        }

        .password-div{
          display: flex;
          input{
            width: 95%;
          }
          .password-toggle{
            width: 5%;
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-bottom: 3px white solid;
          }
        }

        input {
          height: 45px;
          background: none;
          border: none;
          border-bottom: 3px white solid;
          margin-bottom: 1.5rem;
          color: #ffffff;
          font-size: large;

          &::placeholder {
            color: #ffffff;
            font-size: large;
          }

          &:focus {
            outline: none;
            color: #ffffff;
            font-size: large;
          }
        }

        &-action {
          height: 3rem;
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-bottom: 1.5rem;

          div {
            display: flex;
            justify-content: center;
            gap: 1rem;

            input {
              appearance: none;
              background-color: #e5007e;
              margin: 0;
              font: inherit;
              color: currentColor;
              width: 25px;
              height: 25px;
              margin-top: 0.2rem;
              cursor: pointer;
              border: 0.15em solid currentColor;
              border-radius: 0.15em;
              transform: translateY(-0.075em);
              display: grid;
              place-content: center;

              &::before {
                content: "";
                width: 1.4em;
                height: 1.4em;
                transform: scale(0);
                transition: 120ms transform ease-in-out;
                box-shadow: inset 1em 1em rgb(255, 255, 255);
                transform-origin: bottom left;
                clip-path: polygon(
                  14% 44%,
                  0 65%,
                  50% 100%,
                  100% 16%,
                  80% 0%,
                  43% 62%
                );
              }

              &:checked::before {
                transform: scale(0.9);
              }
            }
            @media screen and (max-width: 524px) {
              input {
                margin-top: 0rem;

              }
            }

          }
        }

        &-create-account {
          margin-top: 1.5rem;
          a {
            margin-left: 0.5rem;
            font-weight: 900;
            text-decoration-line: underline;
            cursor: pointer;
          }
        }
      }
    }

    @media screen and (max-width: 1240px) {
      &-left {
        width: 100%;
        height: 10%;
        margin-bottom: 1.2rem;
      }
      &-right {
        width: 100%;
        height: 90%;
        
      }
    }
  }

  @media screen and (max-width: 1240px) {
    &-content {
      flex-direction: column;
    }
   
  }
}
