@import "../../../utils/scss/all";

.btn {
    background-color: $white;
    color: $black;
    padding: 10px 15px;
    transition: all .2s;
    border: 1px solid $white;
    border-radius: 3px;
    display: inline-block;
    text-decoration: none;
    text-align: center;
    font-weight: bold;
    font-size: 14px;

    .loader {
        margin-left: 10px;
        color: $black;
    }

    &:hover, &.thin {
        background-color: transparent;
        color: $white;

        .loader {
            color: $white;
        }
    }

    @media screen and (min-width: 769px) {
        font-size: 18px;
    }

    &.withIcon {
        display: flex;
        align-items: center;
        gap: 10px;
    }
}

.bg-white .btn {
    background-color: $black;
    color: $white;
    border-color: $black;

    .loader {
        color: $white;
    }

    &:hover, &.thin {
        color: $black;
        background-color: transparent;

        .loader {
            color: $black;
        }
    }
}