@import "../../utils/scss/all";

footer {
    width: 100%;
    position: relative;
    z-index: $footer-z-index;
    padding: 0 9.5vw;
    background: linear-gradient(#E5007E, #F090BB, );
    .contact-us {
        padding: 25px;
    }

    #main-footer {
        padding: 25px 0;
        border-top: .5px solid rgba(255, 255, 255, 0.5);

        .logo-footer {
            height: 27px;
            margin-bottom: 25px;
        }

        .footer-container {
            .desc {
                margin-bottom: 50px;
            }
    
            .footer-content {

                nav {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    column-gap: 10px;
                    margin-bottom: 50px;
        
                    .link-list {
                        width: calc(50% - 5px);
        
                        a {
                            font-size: 12px;
                            color: $white;
                            text-decoration: none;
                            letter-spacing: 0.36px;
                            line-height: 30px;
        
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
        
                }

                .infos {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .footer-contact-infos {
                        display: flex;
                        flex-direction: column;
                        gap: 25px;

                        .listSocials {
                            display: flex;
                            gap: 15px;

                            .socialNetwork {
                                img {
                                    height: 20px;
                                    display: block;
                                }
                            }

                        }
                    }

                    .fimg img {
                        height: 30px;
                    }
                }
            }

            .fimg {
                img + img {
                    margin-left: 20px;
                }
            }
        }
    }

    #copyright {
        font-size: 10px;
        text-align: center;
        padding: 7.5px 0 15px;
        width: 100%;
        color: $white;
        border-top: .5px solid rgba(255, 255, 255, 0.5);
    }

    @media screen and (min-width: 769px) {
        padding: 0 7vw;
    }

    @media screen and (min-width: 1024px) {
        #main-footer {
            padding: 40px 0;

            .logo-footer {
                height: 40px;
                margin-bottom: 30px;
            }

            .footer-container {
                display: flex;
                gap: 8.5%;

                .desc-footer {
                    width: 27%;

                    .desc {
                        margin-bottom: 35px;
                    }

                    .fimg img {
                        height: 45px;
                    }
                }

                .footer-content {
                    display: flex;
                    width: 64.5%;
                    flex-direction: row-reverse;
                    justify-content: space-between;
                    align-items: flex-start;

                    nav {
                        width: 55%;
                        margin-bottom: 0;
                        column-gap: 50px;

                        .link-list {
                            width: calc(50% - 25px);

                            a {
                                font-size: 18px;
                                letter-spacing: 0.54px;
                            }
                        }
                    }

                    .infos {
    
                        .footer-contact-infos {
                            flex-direction: column-reverse;
                            gap: 30px;

                            .listSocials {
                                .socialNetwork {
                                    img {
                                        height: 25px;
                                    }
                                }
    
                            }
                        }
                    }
                }
            }
        }

        #copyright {
            font-size: 15px;
            padding: 14px 0 11.5px;
        }
    }
}