@import "../../utils/scss/all";

.pageSection.metiers {
    padding: 50px 9.5vw 0;
    // background: radial-gradient(circle at bottom, rgb(0, 83, 135) 0%, rgb(0, 0, 0) 65%);

    h2 {
        text-align: center;
    }

    .desc {
        text-align: center;
        margin-bottom: 35px;
    }

    .contact-us {
        margin: 0;
    }

    @media screen and (min-width: 1024px) {
        padding: 16.6vh 7vw 0;
        // background: radial-gradient(circle at bottom left, rgb(0, 83, 135) 0%, rgb(0, 0, 0) 55%);

        .desc {
            text-align: center;
            max-width: 617px;
            margin: 0 auto 75px;
        }

        .metiers-container {
            .metiers-drawers:last-child {
                height: 408px;
            }
        }
    }
}