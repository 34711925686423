.loader {
  width: 30px;
  height: 30px;
  display: block;
  position: relative;
  animation: spinRing 1500ms linear infinite;
}
.loader::after{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: auto;
  width: auto;
  border: 5px solid var(--borderColor);
  border-radius: 50%;
  clip-path: polygon(50% 50%, 50% 0%, 100% 0%,100% 80%);
  animation: spinRingInner 1500ms cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@keyframes spinRing {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
@keyframes spinRingInner {
  0%   { transform: rotate(-180deg); }
  50%  { transform: rotate(-160deg); }
  100% { transform: rotate(180deg); }
}