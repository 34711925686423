@import "../../utils/scss/all";

.pageSection.contactForm {
    padding: 40px 9.5vw;
    // background: radial-gradient(circle at bottom, rgb(0, 83, 135) 0%, rgb(0, 0, 0) 100%);
    min-height: 65.6rem;
    
    form {
        display: flex;
        gap: 10px;
        flex-direction: column;

        .form-row {
            display: flex;
            gap: 10px;
            flex-direction: column;
        }

        button[type="submit"] {
            align-self: flex-start;
            cursor: pointer;
            display: flex;
            align-items: center;
        }

        .submit-container {
            display: flex;
            flex-direction: column;
            gap: 15px;
            margin-top: 20px;

            p {
                font-weight: bold;

                &.error {
                    color: red;
                }
            }
        }
    }

    @media screen and (min-width: 1024px) {
        padding: 95px 7vw;
        // background: radial-gradient(circle at bottom left, rgb(0, 83, 135) 0%, rgb(0, 0, 0) 65%);

        form {
            .form-row {
                flex-direction: row;
                gap: 30px;
            }

            .submit-container {
                flex-direction: row;
                align-items: center;
                gap: 25px;
                margin-top: 30px;
            }
        }
    }

    @media screen and (min-width: 1300px) {
        .section-container {
            display: flex;
            gap: 30px;

            h2 {
                width: calc(33% - 30px);
            }

            form {
                width: 66%;
                gap: 20px;
            }
        }
    }
}