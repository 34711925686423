@import "../../utils/scss/all";

.pageSection.sliderVideos {
  padding: 40px 9.5vw;
  $fullWidthSquareHeight: calc(100vw - 9.5vw * 2.5);
  background-color: white;
  color: black;
  h2 {
    margin-bottom: 3rem;
  }
  .gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5rem;
  }

  .skeleton {
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
  }

  @keyframes loading {
    0% {
      background-position: -200% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }

  .main-img-thumbnail {
    width: 100%;
    height: 30rem;
    transition: all 0.2s;
    border-radius: 5px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .lecteur {
      width: 100px;
      background-color: rgba(0, 0, 0, 0.468);
      border-radius: 100%;
      z-index: 2;
      height: 100px;
      display: flex;
      justify-content: center;
      cursor: pointer;
      align-items: center;
      .triangle {
        margin-left: 10%;
        width: 0;
        height: 0;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-left: 30px solid rgba(228, 228, 228, 0.942);
      }
    }

    img {
      width: 100%;
      position: absolute;
      height: 100%;
      border-radius: 5px;
      filter: brightness(0.6);
    }
  }

  @media screen and (max-width: 769px) {
    .gallery {
      grid-template-columns: repeat(
        auto-fill,
        minmax(23rem, 1fr)
      ); /* Deux éléments par ligne, minimum de 250px par élément */
    }
  }

  @media screen and (max-width: 1200px) {
    .gallery {
      grid-template-columns: repeat(
        auto-fill,
        minmax(23rem, 1fr)
      ); /* Deux éléments par ligne, minimum de 250px par élément */
    }
  }
}
