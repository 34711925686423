@import "../../utils/scss/all";

.pageSection.sliderPhotos {
    padding: 50px 9.5vw;
    display: flex;

    .section-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        width: 100%;
    }

    .projets-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 22px;
        width: 100%;

        h2 {
            margin-bottom: 0;
        }
    }

    .swiper {
        width: 100%;
        height: 74vw;

        .swiper-slide {
            display: grid;
            grid-template-columns: 6fr 1.5fr 4fr;
            grid-template-rows: 6fr 4.5fr;
            grid-column-gap: 10px;
            grid-row-gap: 10px;
            padding: 1px;

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            
            div:nth-child(1) { grid-area: 1 / 1 / 2 / 3; }
            div:nth-child(2) { grid-area: 1 / 3 / 2 / 4; }
            div:nth-child(3) { grid-area: 2 / 1 / 3 / 2; }
            div:nth-child(4) { grid-area: 2 / 2 / 3 / 4; }

            div {
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
            }
        }
    }

    .arrows-container {

        .arrow {
            transition: all .2s;
            cursor: pointer;
    
            &.prev {
                transform: rotate(180deg);
            }

            & + .arrow {
                margin-left: 10px;
            }
        }
    }

    @media screen and (min-width: 1024px) {
        padding: 100px 7vw;
        align-items: center;

        .projets-header {
            margin-bottom: 65px;
        }

        .swiper {
            height: 32vw;

            .swiper-slide {
                grid-template-columns: 4fr repeat(2, 2fr);
                grid-template-rows: repeat(2, 1fr);
                grid-column-gap: 30px;
                grid-row-gap: 35px;
            
                div:nth-child(1) { grid-area: 1 / 1 / 3 / 2; }
                div:nth-child(2) { grid-area: 1 / 2 / 3 / 3; }
                div:nth-child(3) { grid-area: 1 / 3 / 2 / 4; }
                div:nth-child(4) { grid-area: 2 / 3 / 3 / 4; }
            }
        }

        .arrows-container {

            .arrow {
                height: 31px;

                & + .arrow {
                    margin-left: 40px;
                }
            }

        }
    }
}