@import "../../utils/scss/all";

.prixFestiv {
    padding: 35px 0 0;
    border-top: .5px solid var(--borderColor);
    margin-top: 30px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    h2 {


        
        margin-bottom: 35px;
        text-align: center;
    }
    li {
        text-align: center;
        margin-bottom: 1rem;
        list-style-type: none;
    }
    

 
    @media screen and (min-width: 769px) {

        h2 {
            text-align: left;
        }
        li {
            text-align: left;
            list-style-type: disc;
            line-height: 3.5rem;
        }

     
    }

    @media screen and (min-width: 1024px) {
        border: none;
        margin-top: 0;
        padding: 120px 0;

        h2 {
            margin-bottom: 50px;
        }
    }

    @media screen and (min-width: 1200px) {
        .section-container {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            
        }

   

        li {
            font-size: 17px;
            letter-spacing: 2px;
            margin-bottom: 1rem;
        }
    }

    @media screen and (min-width: 1400px) {
        li {
            font-size: 17px;
            letter-spacing: 2px;
            margin-bottom: 1rem;
        }
     

    }
}

.pageSection.prixFestiv {
    padding: 50px 9.5vw;
    background: radial-gradient( farthest-side at bottom left, #F090BB  0%, #E5007E 100%);
    border-top: none;
    margin: 0;

    @media screen and (min-width: 1024px) {
        padding: 130px 7vw;
        background: radial-gradient( farthest-side at bottom left, #F090BB  0%, #E5007E 100%);

    }
}