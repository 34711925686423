@keyframes fadeIn {
    from { opacity: 0 }
    to { opacity: 1 }
}

@keyframes maskReveal {
    0% { mask-size: 100% }
    99% { mask-size: 500% }
    100% {
        -webkit-mask-image: var(--letter-mask), linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 1));
        mask-image: var(--letter-mask), linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 1));
    }
}