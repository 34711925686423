@import "../../utils/scss/all";

.pageSection.newsGallery {
  padding: 40px 9.5vw;
  $fullWidthSquareHeight: calc(100vw - 9.5vw * 2.5);
  h2 {
    margin-bottom: 3rem;
  }
  .gallery {
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 20px;

    .news-item {
      width: 100%;

      .img-container {
        overflow: hidden;
      }

      .main-img {
        width: 100%;
        height: $fullWidthSquareHeight;
        object-fit: cover;
        transform: scale(1);
        transition: all 0.2s;
        display: block;
      }

      .title {
        font-style: normal;
        font-size: 14px;
        letter-spacing: 0.42px;
        line-height: 22px;
        font-weight: 900;
        margin: 6px 0 4px;
        display: block;
      }

      .desc {
        font-size: 12px;
        line-height: 20px;
      }

      &:hover .main-img {
        transform: scale(1.1);
      }
    }
  }

  .arrow {
    height: 20px;
    transform: rotate(90deg);
    cursor: pointer;
    transition: all 0.2s;
    margin: 50px auto 0;
    display: block;
  }

  .gallery-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
    .btn {
      font-weight: bold;
      background-color: black;
      color: white;
      margin-bottom: 3rem;
      padding: 10px;
    }
  }

  @media screen and (min-width: 600px) {
    .gallery {
      .news-item {
        width: calc(50% - 10px / 2);

        .main-img {
          height: calc($fullWidthSquareHeight/2 - 10px / 2);
        }
      }
    }
  }

  @media screen and (min-width: 769px) {
    padding: 50px 9.5vw 75px;

    .gallery {
      .news-item {
        width: calc(33.33% - 10px * 2 / 3);

        .main-img {
          height: calc($fullWidthSquareHeight/3 - 10px * 2 / 3);
        }
      }
    }

    .arrow {
      height: 37px;
      margin-top: 75px;

      &:hover {
        transform: rotate(90deg) translateX(10px);
      }
    }
  }

  @media screen and (min-width: 1024px) {
    padding: 75px 7vw 100px;

    .gallery {
      .news-item {
        .title {
          font-size: 22px;
          letter-spacing: 0.66px;
          line-height: 30px;
          margin: 10px 0 12px;
        }

        .desc {
          font-size: 16px;
          letter-spacing: 0.48px;
          line-height: 28px;
        }
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .gallery {
      column-gap: 30px;
      row-gap: 60px;

      .news-item {
        width: calc(25% - 30px * 3 / 4);

        .main-img {
          height: calc($fullWidthSquareHeight/4 - 10px * 3 / 4);
        }
      }
    }
  }
}
