@import "../../utils/scss/all";

#videoLoading {
    position: absolute;
    z-index: 3000;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: $black;
    display: flex;
    justify-content: center;
    align-items: center;

    #homeLoaderVideo {
        width: 100%;
    }
}

#closeVideo {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}