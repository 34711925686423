@import "../../utils/scss/all";

.teammate {
    width: calc(50% - 5px);
    position: relative;

    img {
        display: block;
    }

    .img-container {
        position: relative;
        display: block;

        .photo {
            width: 100%;
            height: 45vw;
            object-fit: cover;
        }

        .entity-hover {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(255, 255, 255, .8);
            opacity: 0;
            transition: opacity .2s;
            color: $black;
            text-align: center;
            padding: 15px 20px;
            overflow: hidden;

            img {
                // width: 70%;
                height: 16%;
            }

            p {
                // font-size: 3vw;
                // line-height: 4.5vw;
                font-size: 12px;
                line-height: 17px;
            }

            .desc {
                max-height: 100%;
            }
        }
    }

    .infos {
        margin-top: 7px;

        .text {
            font-size: 12px;
            line-height: 14px;
            color: white;
            text-decoration: none;

            strong {
                font-size: 14px;
                line-height: 22px;
                letter-spacing: 0.42px;
            }
        }

        .linkedin-url {
            position: absolute;
            display: block;
            bottom: 0;

            .iconLinkedin {
                width: 12px;
            }
        }

        &.withLinkedin {
            padding-bottom: 17px;
        }
    }

    @media screen and (min-width: 769px) {
        width: calc(33% - 20px);

        .img-container {
            .photo {
                height: 30vw;
            }

            .entity-hover {

                p {
                    font-size: 1.33vw;
                    line-height: 2vw;
                }
            }
        }
    }

    @media screen and (min-width: 1024px) {

        .img-container {
            .entity-hover {
                p {
                    // font-size: 16px;
                    font-size: .83vw;
                    // line-height: 25px;
                    line-height: 1.33vw;
                }
            }

            &:hover {
                .entity-hover {
                    opacity: 1;
                }
            }
        }

        .infos {
            margin-top: 20px;
            padding-bottom: 0 !important;
            display: flex;
            justify-content: space-between;
            gap: 10px;

            .text {
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.48px;

                strong {
                    font-size: 22px;
                    line-height: 30px;
                    letter-spacing: 0.66px;
                }
            }

            .linkedin-url {
                position: static;
    
                .iconLinkedin {
                    width: 24px;
                }
            }
        }
    }
}

.swiper-slide .teammate {
    width: 100%;

    .img-container {
        &:hover {
            .entity-hover {
                opacity: 0;
            }
        }

        .photo {
            height: 93vw;
        }
    }

    .infos {
        margin-top: 20px;
        padding-bottom: 0 !important;
        display: flex;
        justify-content: space-between;
        gap: 10px;

        .linkedin-url {
            position: static;

            .iconLinkedin {
                width: 24px;
            }
        }
    }
}

.swiper-slide-active .teammate {
    .img-container:hover {
        .entity-hover {
            opacity: 1;
        }
    }
}

.bg-white .teammate {
    .infos {
        .text {
            color: var(--bgColor);
        }

        .linkedin-url {
            .iconLinkedin {
                filter: brightness(0);
            }
        }
    }
}