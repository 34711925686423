@import "../../utils/scss/all";

.split-flap {
    display: flex;
    justify-content: center;
}

.split-flap [data-kind="digit"] {
    color: $white;
    background-color: transparent;
    border: 1px solid $white;
    font-size: 65px;
    font-family: "Urbanist";
    font-weight: 900;
    width: 60px;
    height: 75px;
    line-height: 75px;
    margin: 0 7px;

    .styles_bottom__1CYW9 {
        background-color: #1A3C52;
    }

    .styles_hinge__2iwrZ {
        background-color: $white;
        height: 1px;
    }
}

.bg-white {
    .split-flap [data-kind="digit"] {
        color: $black;
        background-color: $white;
        border-color: $black;
    
        .styles_bottom__1CYW9 {
            background-color: #EFEFEF;
        }
    
        .styles_hinge__2iwrZ {
            background-color: $black;
        }
    }
}

.split-flap-text {
    font-size: 14px;
    letter-spacing: 0.42px;
    line-height: 22px;
    font-weight: 900;
    margin-top: 7px;
    display: block;
}

@media screen and (min-width: 1024px) {
    .split-flap [data-kind="digit"] {
        font-size: 110px;
        width: 110px;
        height: 132px;
        line-height: 132px;
        margin: 0 15px;
    }

    .split-flap-text {
        font-size: 22px;
        letter-spacing: 0.66px;
        line-height: 30px;
        margin-top: 15px;
    }
}

@media screen and (min-width: 1200px) {
    .split-flap-text {
        text-align: left;
    }
}