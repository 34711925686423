@import "../../utils/scss/all";

.pageSection.infoGroup {
   
    .section-container {
        padding: 40px 9.5vw 75px;
        min-height: 100vh;

        .main-image {
            max-width: 100%;
            display: block;
            margin: auto;
            margin-bottom: 50px;
        }

        h1 {
            font-weight: 900;
            font-size: 22px;
        }

        .tag-list {
            display: flex;
            column-gap: 12px;
            row-gap: 7px;
            flex-wrap: wrap;
            margin-bottom: 33px;

            .tag {
                background-color: var(--bgColor);
                border-radius: 50px;
                color: var(--opposite);
                padding: 5px 15px;
                white-space: nowrap;
                font-size: 12px;
                letter-spacing: 0.36px;
                line-height: 14px;
            }
        }

        .desc {
            margin-bottom: 33px;
        }

        @media screen and (min-width: 1025px) {
            display: flex;
            align-items: center;
            padding: 50px 7vw;

            h1 {
                font-size: 45px;
            }

            .main-image {
                max-width: 40%;
                margin: 0 auto 0 0;
            }

            .infos {
                width: 53%;

                .tag-list .tag {
                    font-size: 16px;
                    letter-spacing: 0.48px;
                    line-height: 16px;
                }
            }
        }

        @media screen and (min-width: 1400px) {

            .main-image {
                max-width: 33%;
                margin: auto;
            }
        }

        @media screen and (min-width: 1600px) {
            padding: 50px 9.5vw;
        }
    }
}