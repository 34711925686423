@import "../../utils/scss/all";

.pageSection.sliderNews {
    padding: 50px 9.5vw;
    display: flex;

    .section-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        width: 100%;
    }

    .slider-container {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }

    h2 {
        width: 100%;
        margin-bottom: 40px;
    }

    .swiper {
        width: 100%;
        overflow: visible;

        .swiper-slide {
            a {
                display: block;

                img {
                    height: calc(100vw - 9.5vw*2);
                    width: 100%;
                    object-fit: cover;
                }

                p {
                    font-size: 14px;
                    font-weight: bold;
                    letter-spacing: 0.42px;
                    margin: 12px 0 7px;
                }

                .thematique {
                    font-size: 12px;
                }
            }
        }
    }

    .arrows-container {
        margin: 35px 0;

        .arrow {
            transition: all .2s;
            cursor: pointer;
    
            &.prev {
                transform: rotate(180deg);
            }

            & + .arrow {
                margin-left: 10px;
            }
        }
    }

    @media screen and (min-width: 600px) {
        .swiper {
            .swiper-slide {
                a {
                    img {
                        height: calc( (100vw - 9.5vw*2)/2 - 25px/2 );
                    }
                }
            }
        }
    }

    @media screen and (min-width: 1024px) {
        padding: 75px 7vw;
        min-height: 100vh;
        align-items: center;

        .section-container {
            justify-content: center;
        }

        h2 {
            margin-bottom: 55px;
            align-self: flex-start;
            width: 85%;
        }

        .swiper {
            .swiper-slide {
                a {
                    img {
                        height: calc( (100vw - 9.5vw*2)/3 - 25px/2 );
                    }

                    p {
                        font-size: 22px;
                        letter-spacing: 0.66px;
                        margin: 15px 0 10px;
                    }

                    .thematique {
                        font-size: 16px;
                        letter-spacing: 0.48px;
                    }
                }
            }
        }

        .arrows-container {
            position: absolute;
            top: 0;
            right: 0;
            margin: 15px 0 0;

            .arrow {
                height: 31px;

                & + .arrow {
                    margin-left: 40px;
                }
            }

        }

        .btn {
            margin-top: 85px;
        }
    }

    @media screen and (min-width: 1300px) {
        .swiper {
            .swiper-slide {
                a {
                    img {
                        height: calc( (100vw - 9.5vw*2)/4 - 25px/2 );
                    }
                }
            }
        }
    }
}