@import "../../utils/scss/all";

.textImgBandeauContainer {

    img {
        width: 100%;
        height: 31vw;
        object-fit: cover;
    }

    .text {
        .desc {
            margin: 10px 0 20px;
        }

        .title {
            font-weight: bold;
        }
    }

    @media screen and (min-width: 1200px) {
        display: flex;
        gap: 30px;
        align-items: center;

        &.reverse {
            flex-direction: row-reverse;
        }

        img {
            width: 74%;
            height: 20vw;
        }

        .text {
            width: 26%;

            .title {
                font-size: 22px;
                letter-spacing: 0.66px;
                font-weight: 900;
                margin-bottom: 25px;
            }

            .desc {
                margin-top: 0;
            }
        }
    }
}