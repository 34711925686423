@import "../../utils/scss/all";

.pageSection.heading {
    padding-top: 0 !important;
    // background: radial-gradient(circle at bottom left, rgb(0, 83, 135) 0%, rgb(0, 0, 0) 75%);
    background-color: #E5007E;
    z-index: $filters-z-index;
    overflow: hidden;
    width: 100%;


    .section-container {
        display: flex;
        gap: 15px;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 95px 9.5vw 50px;

        h1 {
            font-size: 25px;
            font-weight: 900;
        }

        .filters {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;
            color: black !important;
        }
    }

    .buttons-container {
        padding: 0 9.5vw 50px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 15px;
    }

    @media screen and (min-width: 1024px) {

        .section-container {
            padding: 130px 7vw 65px;

            h1 {
                font-size: 50px;
                letter-spacing: 1.5px;
                margin-bottom: 20px;
            }

            .filters {
                flex-direction: row;
                gap: 3%;

                .autocomplete-custom {
                    width: calc(25% - 3%);

                    .MuiFormLabel-root {
                        padding-right: 30px;
                    }
                }

                .search-input {
                    width: 50%;
                }
            }
        }

        .buttons-container {
            padding: 0 7vw 50px;
        }
    }
}