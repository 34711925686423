@import "../../utils/scss/all";

.pageSection.projetListInfo {
  padding: 40px 9.5vw;
  background: radial-gradient( farthest-side at bottom left, #F090BB  0%, #E5007E 100%);

  .list-item {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding-bottom: 6px;
    border-bottom: 1px solid var(--borderColor);

    .title {
      font-weight: bold;
      line-height: normal;
    }

    & + .list-item {
      margin-top: 17px;
    }
  }

  @media screen and (min-width: 1024px) {
    padding: 115px 7vw;


    .list-item {
      flex-direction: row;
      gap: 30px;

      p {
        width: calc(76.5% - 30px);
      }

      .title {
        line-height: 30px;
        width: 23.5%;
      }
    }
  }
}
