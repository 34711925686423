@import "./utils/scss/all";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

div#App {
    background: radial-gradient( farthest-side at bottom left, #F090BB  0%, #E5007E 100%);
}

html {
    font-size: 13px;
    position: relative;
    font-family: "Urbanist";
    color: $white;
    background-color: $black;
    overflow-x: hidden;

    &.menu-open {
        overflow-y: hidden;
    }

    body {
        overflow-x: hidden;
        background: radial-gradient( farthest-side at bottom left, #F090BB  0%, #E5007E 100%);


        h1 {
            font-size: 25px;
            font-weight: 900;
        }

        h2 {
            font-weight: normal;
            font-size: 18px;
            margin-bottom: 20px;
            line-height: 25px;

            strong {
                font-weight: 900;
                font-size: 21px;
            }
        }

        p, ul {
            line-height: 22px;
            letter-spacing: 0.39px;
        }

        a {
            text-decoration: none;
            color: $white;
        }

        button {
            appearance: none;
            cursor: pointer;
            font-family: "Urbanist";
        }

        .arrow.swiper-button-disabled {
            opacity: .5;
        }

        .MuiTextField-root, .MuiFormControl-root {
            *, *::after, *::before {
                color: $white !important;
                border-color: $white !important;
            }

            .Mui-error, .Mui-error::after, .Mui-error::before {
                border-color: red !important;
            }

            input {
                background-color: transparent !important;
                appearance: none !important;
                -webkit-background-clip: text;
                -webkit-text-fill-color: $white !important;
            }
        }

        .pageSection:first-child {
            padding-top: 4.5rem;
        }

        --borderColor: rgba(255, 255, 255, .5);
        --bgColor: #fff;
        --opposite: #000;

        // WHITE BACKGROUND
        .bg-white {
            background-color: $white;
            color: $black;
            margin-top: -1px;

            --borderColor: rgba(0, 0, 0, .5);
            --bgColor: #000;
            --opposite: #fff;

            .arrow {
                filter: brightness(0);
            }

            a {
                color: $black;
            }

            .MuiTextField-root, .MuiFormControl-root {
                *, *::after, *::before {
                    color: $black !important;
                    border-color: $black !important;
                }

                input {
                    -webkit-text-fill-color: $black !important;
                }
            }
        }

        // BLUE GRADIENT BACKGROUND
        .bg-blue {
            background: radial-gradient(circle farthest-side at bottom left, rgb(0, 83, 135) 0%, rgb(0, 0, 0) 75%);
        }
        .bg-pink {
            background: radial-gradient( farthest-side at bottom left, #F090BB  0%, #E5007E 100%);
        }

        .axeptio_mount, .axeptio_mount * {
            z-index: $axeptio-z-index !important;

            .hMxmxV.hMxmxV.hMxmxV.hMxmxV.hMxmxV.hMxmxV {
                z-index: $axeptio-z-index !important;
            }
        }

        @media screen and (min-width: 768px) {
        
            p, ul {
                // font-size: 18px;
                font-size: 17px;
                line-height: 30px;
                letter-spacing: 0.54px;
            }
        }

        @media screen and (min-width: 1024px) {

            background: radial-gradient( farthest-side at bottom left, #F090BB  0%, #E5007E 100%);


            // BLUE GRADIENT BACKGROUND
            .bg-blue {
                background: radial-gradient(circle farthest-side at bottom left, rgb(0, 83, 135) 0%, rgb(0, 0, 0) 55%);
            }

            .bg-pink {
                background: radial-gradient( farthest-side at bottom left, #F090BB  0%, #E5007E 100%);
            }

            .pageSection:first-child {
                padding-top: 5.8rem;
            }

            h1 {
                font-size: 50px;
                letter-spacing: 1.5px;
            }

            h2 {
                font-size: 35px;
                letter-spacing: 1.05px;
                line-height: 45px;

                strong {
                    font-size: 45px;
                }
            }
        }
    }
}