@import "../../utils/scss/all";

.pageSection.articleRH {
    padding: 35px 9.5vw;

    h1 {
        position: relative;
        font-size: 25px;
        font-weight: 900;
        line-height: 25px;
        margin-bottom: 10px;
    }

    .infos {
        position: relative;
        font-size: 12px;
        line-height: 20px;
    }

    .main-content {
        margin-top: 20px;
        
        a {
            text-decoration: underline;
        }
    }

    @media screen and (min-width: 1024px) {
        padding: 60px 7vw;

        h1 {
            font-size: 50px;
            line-height: 55px;
            letter-spacing: 1.5px;
        }

        .infos {
            font-size: 16px;
        }
    }
}